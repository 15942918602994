import gtmPush from '@/services/utils/gtm-push.js'

const upiCustomMethod = {
  id: 999,
  type: 'upi',
  logo: require('@/assets/upi.svg'),
  strategy: 'form',
  min_add_funds_amount: 30,
  fee_percent: 0,
  translations: [
    {
      id: 1,
      locale: 'en',
      name: 'UPI',
      description: 'Funds will be loaded to your account in 3 working days after you proceed the payment'
    },
    {
      id: 2,
      locale: 'ru',
      name: 'UPI',
      description: 'Денежные средства будут доступны на Вашем балансе в течение 3 рабочих дней после завершения платежа'
    },
    {
      id: 24,
      locale: 'de',
      name: 'UPI',
      description: 'Das Geld wird Ihrem Konto innerhalb von 3 Arbeitstagen nach Ihrer Zahlung gutgeschrieben'
    },
    {
      id: 25,
      locale: 'es',
      name: 'UPI',
      description: 'Los fondos se cargarán en su cuenta en 3 días laborables después de proceder al pago'
    }
  ],
  bonuses: []
}

export default {
  data() {
    return {
      extendedPaymentMethods: [],
      upiModalIsShown: false
    }
  },
  created() {
    if (window.localStorage.getItem('ab_test_fake_door_upi')) {
      this.addPaymentMethod(this.paymentMethods)
      gtmPush({
        event: 'show_fake_door_upi'
      })
    } else {
      this.extendedPaymentMethods = this.paymentMethods
    }
  },
  methods: {
    addPaymentMethod(methods) {
      const methodsCopy = JSON.parse(JSON.stringify(methods))
      methodsCopy.splice(2, 0, upiCustomMethod)
      this.extendedPaymentMethods = methodsCopy
    },
    checkPaymentMethod({ type }) {
      this.upiModalIsShown = type === 'upi'
      if (this.upiModalIsShown) {
        gtmPush({
          event: 'ab_test_fake_door_upi'
        })
      }
      return this.upiModalIsShown
    }
  }
}
