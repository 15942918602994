export default {
  WIRE_TRANSFER: 'wire',
  PAXUM: 'paxum',
  WEBMONEY: 'webmoney',
  CAPITALIST: 'capitalist',
  ROBOKASSA: 'robokassa',
  STRIPE: 'stripe_checkout',
  PERFECT_MONEY: 'perfect_money',
  QIWI: 'qiwi',
  COINBASE: 'coinbase',
  SECURION_PAY: 'securion_pay',
  MANUAL: 'manual',
  PAYEER: 'payeer'
}
