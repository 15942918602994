<template>
  <v-dialog :value="isShown" max-width="600px" class="white" @click:outside="closeDialog()">
    <v-card class="overflow-hidden">
      <v-card-title class="d-flex align-center justify-space-between secondary">
        UPI
        <button type="button" @click="closeDialog()">
          <v-icon class="black--text opacity-40" size="18">
            $close-outlined
          </v-icon>
        </button>
      </v-card-title>
      <v-card-text class="px-4 pt-9 pb-5 text-body-1 font-weight-bold black--text text-center text-uppercase">
        We are working on integrating this payment method, stay tuned for updates.
      </v-card-text>

      <v-card-actions class="d-flex flex-column px-8 pb-8 pt-6">
        <c-btn
          depressed
          large
          block
          color="primary"
          label="Return"
          @click="closeDialog()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'UpiDialog',
    components: {
      CBtn
    },
    props: {
      isShown: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      closeDialog() {
        this.$emit('close-upi')
      }
    }
  }
</script>
